<template>
  <v-container class="tw-pt-0" v-if="dataLoaded">
    <v-row class="text-left">
      <v-col>
        <h3 class="tw-text-25 tw-text-dark-green tw-font-semibold">
          Let's Invite the Crew
        </h3>
        <p class="tw-text-light-grey tw-text-base tw-leading-22">
          Copy the link below and text or email it to your crew to invite them
          to the trip. You can edit your invite at any time.
        </p>
        <button
          class="tw-bg-white tw-rounded-md tw-shadow-custom tw-p-3 tw-font-semibold tw-flex tw-flex-row tw-items-center"
          @click="copyLink"
        >
          <span class="tw-mr-6" v-html="copyBtnLabel"></span>
          <v-icon size="16">mdi-content-copy</v-icon>
        </button>
        <p class="tw-text-light-grey tw-text-base tw-leading-22 tw-mt-8">
          We’ll notify you as your crew submits responses. Be sure your
          notifications are turned on so you don’t miss updates.
        </p>
        <v-btn
          class="tw-tracking-normal tw-mt-8"
          color="secondary"
          block
          x-large
          depressed
          rounded
          target="_blank"
          :to="{
            name: 'Invitation',
            params: { uuid: trip.trip_invitation.uuid }
          }"
          >Preview Invite</v-btn
        >
        <v-btn
          class="tw-tracking-normal tw-mt-3"
          block
          color="secondary"
          depressed
          x-large
          rounded
          outlined
          :to="{
            name: 'CreateInvitationForm',
            params: {
              id: trip.id,
              inviteId: trip.trip_invitation.uuid
            }
          }"
          >Edit Invite</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="text-left tw-mt-12">
      <v-col cols="12">
        <h3 class="tw-text-25 tw-text-dark-green tw-font-semibold">
          Trip Survey
        </h3>
        <p class="tw-text-light-grey tw-text-base tw-leading-22 tw-mt-2">
          Your trip survey will be attached to your invite. Tap the Trip Survey
          widget in your Trip Hub to make changes or view live results
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-btn
          class="tw-tracking-normal"
          color="primary"
          block
          x-large
          rounded
          @click="goToCrew"
          >Done</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
//import { DateTime } from "luxon";

export default {
  name: "InviteCrew",
  mixins: [FormattedDateRange],
  data() {
    return {
      dataLoaded: false,
      copyBtnLabel: "Copy Invite Link"
    };
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },
  methods: {
    copyLink() {
      const msg =
        "Hi! I'm planning a group trip on Let's Jetty. Hope you can make it. ✈️ \n\n";
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          msg +
            window.location.host +
            "/trip-invitation/" +
            this.trip.trip_invitation.uuid
        );
      }

      this.copyBtnLabel = "Copied!";
      setTimeout(() => {
        this.copyBtnLabel = "Copy Invite Link";
      }, 2000);
    },
    goToCrew() {
      this.$router.push({
        name: "CrewListRoute",
        params: { id: this.$route.params.id }
      });
    },
    goToSurvey() {
      sessionStorage.setItem("clicked_survey_creation", this.$route.path);
      sessionStorage.setItem("fromInvitePreview", true);
      this.$router.push({
        name: "SurveyCreateRouteForm",
        params: { id: this.trip.id }
      });
    }
  },
  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Send Invite",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    this.dataLoaded = true;
  }
};
</script>
<style lang="scss">
.j-panel-content > .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
