var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataLoaded)?_c('v-container',{staticClass:"tw-pt-0"},[_c('v-row',{staticClass:"text-left"},[_c('v-col',[_c('h3',{staticClass:"tw-text-25 tw-text-dark-green tw-font-semibold"},[_vm._v(" Let's Invite the Crew ")]),_c('p',{staticClass:"tw-text-light-grey tw-text-base tw-leading-22"},[_vm._v(" Copy the link below and text or email it to your crew to invite them to the trip. You can edit your invite at any time. ")]),_c('button',{staticClass:"tw-bg-white tw-rounded-md tw-shadow-custom tw-p-3 tw-font-semibold tw-flex tw-flex-row tw-items-center",on:{"click":_vm.copyLink}},[_c('span',{staticClass:"tw-mr-6",domProps:{"innerHTML":_vm._s(_vm.copyBtnLabel)}}),_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-content-copy")])],1),_c('p',{staticClass:"tw-text-light-grey tw-text-base tw-leading-22 tw-mt-8"},[_vm._v(" We’ll notify you as your crew submits responses. Be sure your notifications are turned on so you don’t miss updates. ")]),_c('v-btn',{staticClass:"tw-tracking-normal tw-mt-8",attrs:{"color":"secondary","block":"","x-large":"","depressed":"","rounded":"","target":"_blank","to":{
          name: 'Invitation',
          params: { uuid: _vm.trip.trip_invitation.uuid }
        }}},[_vm._v("Preview Invite")]),_c('v-btn',{staticClass:"tw-tracking-normal tw-mt-3",attrs:{"block":"","color":"secondary","depressed":"","x-large":"","rounded":"","outlined":"","to":{
          name: 'CreateInvitationForm',
          params: {
            id: _vm.trip.id,
            inviteId: _vm.trip.trip_invitation.uuid
          }
        }}},[_vm._v("Edit Invite")])],1)],1),_c('v-row',{staticClass:"text-left tw-mt-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"tw-text-25 tw-text-dark-green tw-font-semibold"},[_vm._v(" Trip Survey ")]),_c('p',{staticClass:"tw-text-light-grey tw-text-base tw-leading-22 tw-mt-2"},[_vm._v(" Your trip survey will be attached to your invite. Tap the Trip Survey widget in your Trip Hub to make changes or view live results ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"tw-tracking-normal",attrs:{"color":"primary","block":"","x-large":"","rounded":""},on:{"click":_vm.goToCrew}},[_vm._v("Done")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }